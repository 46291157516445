import { createSlice } from "@reduxjs/toolkit";
debugger
  // const userDetil = JSON.parse(localStorage.getItem("userDetails")) || "";



  const storedUserDetails = localStorage.getItem("userDetails");
  const userDetails = storedUserDetails && storedUserDetails !== "undefined" ? JSON.parse(storedUserDetails) : "";
const theme = localStorage.getItem('theme')



const initialState = {
  token: null,
  userdetail: userDetails,
  loading: false,
  apierror: null,
  theme: theme || 'light',
  snackbaralert: {
    active: false,
    message: "",
    type: "success",
  },
};



const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
    setApierror: (state, action) => {
      state.apierror = action.payload;
    },
    cleanApierror: (state, action) => {
      state.apierror = null
    },
    clearToken: (state, action) => {
      state.token = null;
    },
    setUserDetail: (state, action) => {
      state.userdetail = action.payload;
      localStorage.setItem("userDetails", JSON.stringify(action.payload));

    },
    setSnackbaralert: (state, action) => {
      state.snackbaralert = action.payload;
    },
  },
});

export const {
  setToken,
  clearToken,
  cleanApierror,
  setSnackbaralert,
  setUserDetail,
  setApierror,
  setLoader,
  setTheme
} = authSlice.actions;
export default authSlice.reducer;
