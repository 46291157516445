import { Button } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";


export const BackButton = ({onClick}) =>{
  
return<>

<Button sx={{width:'100%' ,padding:'6px 0px',margin:'0px',backgroundColor:'#4d57a6'}} variant="contained" onClick={onClick}>
    <ArrowBackIcon/>
    Back To MAIN MENU</Button>
</>
}

export const MainCommanButton= ({onClick, text}) =>{ 
    return<> 
    <Button sx={{width:'100%' ,padding:'6px 0px',margin:'0px',backgroundColor:'#4d57a6'}} variant="contained" onClick={onClick}>
        <ArrowBackIcon/>
        {text}</Button>
    </>
    }