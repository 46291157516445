

// src/app/store.jsx
import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../store/feature/authSlice'
import { thunk } from 'redux-thunk';

// Configure the Redux store
export const store = configureStore({
  reducer: {
    // Add the auth slice reducer to the store
    auth: authReducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(thunk),
});

export default store;



