import React, { useEffect, useState } from 'react';
import { MainCommanButton } from '../../common/backbutton';
import { useNavigate } from 'react-router-dom';
import errorimg from "../../../assets/images/error.jpg"
import { useDispatch, useSelector } from 'react-redux';
import { cleanApierror } from '../../../store/feature/authSlice';
import { login } from '../../../routesconfig/constant';
const ErrorFound = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const error = useSelector(state => state.auth.apierror);
  const handleClick = ()=>{
    navigate(login)
  }


  return (
    <>
        <div className="login-body cmd">

            <div className="row">
                <div className=" d-none d-lg-block col-lg-6">
                    <div className="cmd">
                        <div className="login-image">
                            <img src={errorimg} alt="" />
                        </div>
                    </div>

                </div>
                <div className="col-lg-6 cmd ">
                 
                        <div className='technical_error '>
                            <h5>Technical issue</h5>
                            <p style={{color:'red'}}>!{error}</p>
                            <p>Oops! Looks like there has a issue in server side</p>
                            <div className="">
                            <MainCommanButton text={"EXIT"} onClick={handleClick}/>
                            </div>
                        </div>
                   
                </div>

            </div>
        </div>
    </>
)}

export default ErrorFound;




