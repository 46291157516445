import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DefaultLayout from '../component/pages/layout';
import Home from '../component/pages/home';
import { accountstatement, akbarlottery, amarakbar, andarbahargame,avaitorgame,casino, casinogameresult, changeclientpassword, coinflip, dragontiger, errorresult, home, inplay, inplaysession, instruction, katrinadepika, login, luckyseven, myledger, profile, technicalerror, teenpattigame, } from './constant';
import 'bootstrap/dist/css/bootstrap.min.css';
import Inplay from '../component/pages/inplay';
import ChangeClientPassword from '../component/pages/changeClientPassword';
import InplaySession from '../component/pages/inplaysession';
import MyLedger from '../component/pages/myledger';
import ProfilePage from '../component/pages/profilepage';
import { useSelector } from 'react-redux';
import Casino from '../component/casinoplay/casino';
import AkbarLottery from '../component/casinoplay/akbarlottery';
import CoinFlip from '../component/casinoplay/flipcoin';
import AmarAkbar from '../component/casinoplay/amarakbar';
import TeenPatti from '../component/casinoplay/teenpatti';
import DragonTiger from '../component/casinoplay/dragontiger';
import LuckySeven from '../component/casinoplay/luckeyseven';
import AndarBahar from '../component/casinoplay/andarbahar';
import KatrinaDeepika from '../component/casinoplay/katrinadeep';
import Avaitor from '../component/casinoplay/avaitor';
import CasinoGameResult from '../component/casinoplay/cassinogameresult';
import LoginForm from '../component/pages/login';
import PageNotFound from '../component/pages/Pageerror/pagenotFound';
import Unauthorized from '../component/pages/Pageerror/unauthorized';
import TexhnicalError from '../component/pages/Pageerror/techerror';
import InstructionsPage from '../component/instructionpage';
import ErrorFound from '../component/pages/Pageerror/errorfound';
import AccountStatement from '../component/pages/statement';




const RouteConfig = () => {

  const accessToken = localStorage.getItem('authToken')
  const authToken = useSelector(state => state.auth.token);
  let token = authToken || accessToken;
  return (
    <Routes>
      {/* <Route path={'*'} element={token ? <PageNotFound/> : <Unauthorized />} /> */}
      <Route path={'*'} element={token ? <PageNotFound/> : <LoginForm />} />
      <Route path={technicalerror} element={<TexhnicalError/>} />
      <Route path={errorresult} element={<ErrorFound/>} />
      {token ?
(
        < Route path={login} element={<DefaultLayout />} >
          <Route index path={home} element={<Home />} />
          <Route path={changeclientpassword} element={<ChangeClientPassword />} />
          <Route path={inplay} element={<Inplay />} />
          <Route path="/inplaysession/:matchId" element={<InplaySession />} />
          <Route path={profile} element={<ProfilePage />} />
          <Route path={myledger} element={<MyLedger />} />
          <Route path={casino} element={<Casino />} />
          <Route path={akbarlottery} element={<AkbarLottery/>} />
          <Route path={coinflip} element={<CoinFlip/>} />
          {/* <Route path={amarakbar} element={<AmarAkbar/>} /> */}
          <Route path={teenpattigame} element={<TeenPatti/>} />
          <Route path={dragontiger} element={<DragonTiger/>} />
          <Route path={luckyseven} element={<LuckySeven/>} />
          <Route path={andarbahargame} element={<AndarBahar/>} />
          <Route path={katrinadepika} element={<KatrinaDeepika/>} />
          <Route path={avaitorgame} element={<Avaitor/>} />
          <Route path={casinogameresult} element={<CasinoGameResult/>} />
          <Route path={instruction} element={<InstructionsPage/>} />
          <Route path={accountstatement} element={<AccountStatement/>} />
          
        </Route>
        )      
          :
       ( <Route path={login} element={<LoginForm />} />) } 
      
    </Routes >
  );
};

export default RouteConfig;
