import { useDispatch } from "react-redux";
import { GetAccountstatement, GetLedgerData, LoginApi, UserupdatePassword } from "../../service/apiconst";
import { usePostAPI, usePutAPI } from "../../service/apiServices";

function UseLogin() {
  const dispatch = useDispatch();

  return usePostAPI({
    contenttype: false,
    url: LoginApi(),
    dispatch: (e) => {
 
      if (e.payload.type !== "success") {
        dispatch({ type: e.type, payload: e.payload });
      }
    },
  });
}

export default UseLogin;

export function ChangePassword() {
  const dispatch = useDispatch();

  return usePostAPI({
    contenttype: false,
    url: UserupdatePassword(),
    dispatch: (e) => {
      dispatch({ type: e.type, payload: e.payload });
    },
  });
}

export function UseAccountStatement() {
  const dispatch = useDispatch();

  return usePostAPI({
    contenttype: false,
    url: GetAccountstatement(),
    dispatch: (e) => {
      // dispatch({ type: e.type, payload: e.payload });
    },
  });
}

export function UseLedgerData() {
  const dispatch = useDispatch();

  return usePostAPI({
    contenttype: false,
    url: GetLedgerData(),
    dispatch: (e) => {
      // dispatch({ type: e.type, payload: e.payload });
    },
  });
}

