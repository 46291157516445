import React from 'react';
import { Bars } from 'react-loader-spinner';

const CommonLoader = ({ align = 'center', justify = 'center', height = '100vh' }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: justify, // Use the passed justify prop
        alignItems: align, // Use the passed align prop
        width: "100%",
        height: height, // Use the passed height prop
        color: "red",
        position:'fixed',
        top:0,
        left:0,
        background:'rgba(255, 255, 255, 0.8)',
        zIndex:'1000'
      }}
    >
      <Bars
        height="80"
        width="80"
        color="#009270"
        ariaLabel="bars-loading"
        visible={true}
      />
    </div>
  );
};

export default CommonLoader;
