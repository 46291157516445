import React, { useEffect, useState } from 'react';
import CommanTable from '../common/ledgertable';
import { BackButton } from '../common/backbutton';
import { useNavigate } from 'react-router-dom';
import { casino, home } from '../../routesconfig/constant';
import { ConfirmationDialog } from '../common/dialog';
import { Button } from '@mui/material';
import check from '../../assets/images/check.png'

const AkbarLottery = () => {
  const navigate = useNavigate();

  const [betconform ,setBetconform] = useState(false)
const [selectamout , setSelectAmount] = useState('')
  const buttonValuesi = [
    { label: '₹ 100', value: 1000 },
    { label: '₹ 500', value: 5000 },
    { label: "₹ 1000", value: 10000 },
    { label: "₹ 5000", value: 25000 },
    { label: "₹ 10K", value: 50000 },
    { label: "₹ 20K", value: 50000 },
    
]

  const handleClick = () => {
    navigate(casino);
  };

  const handelAmountclick =(value) =>{
    setSelectAmount(value)
  }
  const handleClose = () => {
    setBetconform(false)
  };
  const handeldialog =() =>{
    setBetconform(true)
  }
  const handleConfirmbet =() =>{
    setBetconform(false)
  }

  // Generate 100 buttons
  const buttons = [];
  for (let i = 1; i <= 100; i++) {
    buttons.push(
      <button onClick={handeldialog} key={i} className="mini-button">
        {i}
      </button>
    );
  }

  return (
    <>
    <div className='lottery_section casino_container'>  
            <div className='akbar_header'>Akbar Lottery</div>

      {/* Render the buttons */}
      <div className="mini-buttons-container">
        {buttons}
      </div>
    <ConfirmationDialog
                    open={betconform}
                    onClose={handleClose}
                    title="Conform your Bet :-"

                    content=<div>
                    <input
                    value={selectamout}
                    />
                    {buttonValuesi.map((btn, index) => (
                        <Button
                        sx={btn.style}
                            key={index}
                            onClick={() => handelAmountclick(btn.value)}
                       
                        >{btn.label}</Button>
                    ))}
                    </div>
                    onConfirm={handleConfirmbet}
                />
      <BackButton onClick={handleClick} />
      </div>

    </>
  );
};

export default AkbarLottery;
