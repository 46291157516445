import { useNavigate } from 'react-router-dom';
import avaitorbg from '../../assets/images/AvaitotStyle.jpg'
import { casino, home } from '../../routesconfig/constant';
import { BackButton } from '../common/backbutton';

const Avaitor =() => {
const navigate = useNavigate()
    const handleClick = () => {
        navigate(casino);
      };
    
        return (
            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                {/* Image as background */}
                <img
                    src={avaitorbg}
                    alt="Background"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
                {/* Overlay text */}
                <div
                    style={{
                        width:'100px',
                        height:'100px',
                        position: 'absolute',
                        top: '50%',
                        left: '20%',
                        transform: 'translate(-50%, -50%)',
                        color: 'black',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                    }}
                >
<div className='cmd' style={{width:"400px" , height:'100px' ,borderRadius:'20px',backgroundColor:'white' }}>
                    <h4><span style={{color:'red'}}>AVAITOR</span>Coming Soon....</h4>
                    </div>
                </div>
                <BackButton onClick={handleClick} />
            </div>

    )

}
export default Avaitor