import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dropdown from '../inputfields';
import { typeofcasino } from '../models';
import { Button } from '@mui/material';
import CommanTable from '../common/commantable';
import { BackButton } from '../common/backbutton';
import { Navigate, useNavigate } from 'react-router-dom';
import { home } from '../../routesconfig/constant';



const CasinoGameResult =() =>{
const navigate = useNavigate()
 
const handleBasicInputChange =() =>{

}

const columns = [
    { id: 'roundid', label: 'RoundId', minWidth: 120, align: 'center' },
    { id: 'winner', label: 'Winner', minWidth: 120, align: 'center' },
  
];

const handleClick =() =>{
    navigate(home)
} 

const rows = [
    { roundid: 'R001', winner: 'Player 1' },
    { roundid: 'R002', winner: 'Player 2' },
    { roundid: 'R003', winner: 'Player 3' },
    { roundid: 'R004', winner: 'Player 4' },
    { roundid: 'R005', winner: 'Player 5' },
    { roundid: 'R006', winner: 'Player 6' },
    { roundid: 'R007', winner: 'Player 7' },
    { roundid: 'R008', winner: 'Player 8' },
    { roundid: 'R009', winner: 'Player 9' },
    { roundid: 'R010', winner: 'Player 10' }
];

return(
<>
<div className="row">
    <div className="col-md-4 ">
       <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker label="Basic date picker" />
      </DemoContainer>
    </LocalizationProvider>
  </div>

<div className='col-md-4'>
 <Dropdown
   field={
    {
        id: "casinotype",
        label: "Casino",
        type: 'select',
        value: "",
        required: true,
        error: "",
        helperText: 'Enter Full Name Required',
        item:typeofcasino,
        onChange: handleBasicInputChange
    }
}
 />
 </div>
 

    <div className="col-4 mt-3">
    <Button variant="contained">Submit</Button>
    </div>
</div>

<CommanTable columns={columns} rows={rows} />
<BackButton onClick={handleClick} />
</>


)

}
export default CasinoGameResult

