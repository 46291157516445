import { useDispatch } from "react-redux";
import { UserTheme } from "../../service/apiconst";
import { useGetAPI } from "../../service/apiServices";

const UseTheme = (theme) => {
    const dispatch = useDispatch();

    return useGetAPI({
        url: UserTheme(theme),
        autoRunStop: false,
        dispatch: (e) => {
            dispatch(e);
        },
    });
}
export default UseTheme;