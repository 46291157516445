
export const typeofcasino =[
{name: "Teenpatti", value:1 },
{name: "DragonTiger", value:2},
{name: "AndarBaher", value:3},
{name: "AkbarLottry", value:4},
{name: "KatrinaLottery", value:5 },
{name: "AmarAkbarAnthony", value:6},

]



export const OperationType = {
    ProfitAndLoss : 1,
    Commission : 2,
    Account : 3
    }