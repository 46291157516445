import React from 'react';
import { MainCommanButton } from './common/backbutton';
import { home } from '../routesconfig/constant';
import { useNavigate } from 'react-router-dom';

const InstructionsPage = () => {
    const navigate = useNavigate()
const handleClick =() =>{
    navigate(home)
}

  return (
  <>
  <MainCommanButton text={"MAIN MENU"} onClick={handleClick}/>
 
    <div className='instructionPage' style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h3>कृपया नियमों को समझने के लिए यहां कुछ मिनट दें, और अपने अनुसार समझ लें</h3>
      <ul>
        <li>1. सभी डीलर्स से निवेदन है कि क्लाइंट्स को साइट के रूल्स समझाने के बाद ही सौदे करवायें।</li>
        <li>2. अगर आप इस एग्रीमेंट को ऐक्सेप्ट नहीं करते हैं तो कोई सौदा नहीं कीजिये।</li>
        <li>3. सर्वर या वेबसाइट में किसी तरह की खराबी आने या बंद हो जाने पर केवल किए गए सौदे ही मान्य होंगे | ऐसी स्तिथि में किसी तरह का वाद-विवाद मान्य नहीं होगा कंपनी के पास अधिकार है कि वे किसी भी ऐड/शर्तों को निलंबित/रद्द करें अगर यह गलतफहमी साबित होता है। उदाहरण स्वरूप, वीपीएन/रोबोट-प्रयोग/एक ही आईपी से एकाधिक प्रवेश की स्थिति में और अन्य। ध्यान दें: केवल जीतने वाली शर्तें ही रद्द की जाएँगी। कंपनी के पास अधिकार है कि वे किसी भी मैच की कोई भी सौदे (केवल जीतने वाली सौदे) किसी भी समय मैच के किसी भी बिंदु पर रद्द करें अगर कंपनी का विश्वास होता है कि उस विशेष मैच में कोई धोखाधड़ी/गलत कृत्य हो रहा है खिलाड़ियों द्वारा (चाहे वो बैट्समैन/गेंदबाज हों)।</li>
      
      </ul>
      <h3>BOOKMAKER</h3>
      <p>
      किसी भी कारण से किसी भी टीम को फायदा होगा या नुकसान, इसमें हमारी कोई जवाबदारी नहीं है
        <br />
        कंपनी के पास किसी भी आईडी/बेटस को हटाने/शून्य करने का अधिकारहै, यदि वह अमान्यपाया जाता है। उदाहरण के लिए vpn/robot-use/एक ही IP से एक से अधिक बेटसएक ही समय में एक से अधिक दांव (पंचिंग) और अन्य के मामले में। नोट: केवल जीतने वाली बेट ही रद्द कर दी जाएगी।
        <br/>
        रिजल्ट या सेशन के बारे में किसी भी प्रश्न के लिए रिजल्ट के 4 दिनों के भीतर संपर्क किया जाना चाहिए, इसे इवेंट के 4दिनों के बाद मान्य नहीं माना जाएगा।
यदि दो टीमों के अंक समान होते हैं, तो रिजल्ट पॉइंट टेबल के आधार पर दिया जाएगा
<br/>
किसी भी स्थिति में अगर वीडियो बाधित/बंद हो जाता है तो किसी तकनीकी समस्या के कारण इसे जारी नहीं रखा जा सकता है बुकमेकर
बाजार को रद्द कर दिया जाएग
      </p>
      <h3>CASINO RULES</h3>
      <ul>
        <li>1. यदि किसी कैसिनो गेम में किसी टेक्निकल इशू की वजह से रिजल्ट नहीं डलता है तो उस गेम के क्लाइंट को कॉइन वापिस कर दिए जायेंगे, ऐसी स्थिति मे कोई वाद विवाद मान्य नहीं होगा</li>
      
      </ul>
      <h3>FANCY RULES</h3>
      <ul>
        <li>1. मैच टाई होने पर सभी फैंसी बेटसमान्य होंगे।</li>
        <li>2. टॉस या खराबमौसम की स्थिति से पहले सभी एडवांस फैंसीयां ससपेंडकर दी जाएंगी।</li>
        <li>3 टेक्निकल एरर या किसी भी परिस्थिति में किसी भी फैंसी को कैंसिल कर दिया जाता है और इवेंट फिर से शुरू नहीं होता है,तो सभी पिछले दांव मान्य होंगे (हार/जीत के आधार पर)।</li>
        <li>4. यदि किसी मामले में गलत रेट फैंसी में दी गई है तो उस फैंसी बेटस को रद्द कर दिया जाएगा।</li>
        <li>5.किसी भी परिस्थिति में सभी एक्सचेंज में मैनेजमेंट का निर्णय अंतिम होगा। ऑनलाइन पोर्टल में कोई बेमेल होने पर हमारा स्कोरकार्ड मान्य माना जाएगा</li>
        <li>6. यदि ग्राहक गलत तरीके से बेट लगाता है तो हम डिलीट करने के लिए उत्तरदायी नहीं होंगे, कोई बदलाव नहीं किया जाएगा और बेट को कन्फर्म बेट माना जाएगा।</li>
        <li>7. किसी टेक्निकल एरर के कारण मार्किट खुला है और रिजल्ट आ गया है रिजल्ट के बाद भी सभीगलत दांव हटा दिए जाएंग। इसमें कोई वाद विवाद मान्य नहीं होगा।।</li>
        <li>8. हमारे एक्सचेंज में मैनुअल बेट्स ( फ़ोन कॉल के द्वारा )स्वीकार नहीं किए जाते हैं</li>
        <li>9. हमारा एक्सचेंज हमारे टीवी में 5 सेकंड की देरी प्रदान करेगा।</li>
        <li>10. कंपनी के पास किसी भी आईडी/बेट को अमान्य पाए जाने पर ससपेंड/शून्य करने का अधिकार सुरक्षित है। उदाहरण के लिए vpn/robot-use/एक ही IP से कई एंट्री/एक ही समय में कई बेट (पंचिंग) और अन्य के मामले में। नोट: केवल जीतने वाली बेट को रद्द कर दिया जाएगा, उदाहरण के लिए: यदि हमें किसी भी आईडी से ऐसी प्रविष्टियां (ऊपर उल्लिखित) मिलती हैं और उनकी बेट हैं (200000 6 ओवर सेशन में 40 की दर से और 200000 48 की दर से वापस) और वास्तविक स्कोर 38 है, 40 ले की बेट रद्द कर दी जाएगी और 48 बैक की बेट मान्यमानी जाएगी।</li>
        <li>11. कंपनी मैच के किसी भी बिंदु पर किसी भी फैंसी के किसी भी बेटस (केवल जीतने वाले दांव) को रद्द करने का अधिकाररखती है यदि कंपनी का मानना है कि खिलाड़ियों (या तो बल्लेबाज/गेंदबाज) द्वारा उस फैंसी में कोई धोखा/गलत किया जा रहा है।</li>
        <li>12. एक बार जब हमारा एक्सचेंज यूजरनेम और पासवर्ड दे देता है तो पासवर्ड बदलने की जिम्मेदारी आपकी होती है।</li>
        <li>13. पेनल्टी रन को सभी फैंसी में गिना जाएगा।</li>
        <li>14. किसी भी गलत गतिविधियों का पता चलने पर यूजर आईडी ब्लॉक कर दिया जाएगा, इस संबंध में कोई प्रश्न स्वीकार नहीं किया जाएगा।</li>
        <li>15. क्लाइंट आईडी के दुरुपयोग के लिए हमारा एक्सचेंज जिम्मेदार नहीं है।</li>
        <li>16.नो बॉल के मामले में, गलत बेटस हटा दिए जाएंगे, तो अंतिम निर्णय मैनेजमेंट का होगा।</li>
        <li>17.मैच अबॉण्डेड या खराब मौसम होने पर जो सेशन, पार्टनरशिप और खिलाडी रनिंग में है या खिलाडी रिटायर हुआ है वो रनिंग सौदे केंसल नहीं होंगे । और जो सेशन कम्पलीट है उनके हिसाब से कोइन्स कम या ज्यादा होंगे। और रिजल्ट आने पे जो खिलाडी जहा है वो वही माने जायेंगे।</li>

        <li>BOWLER RUN FANCY<br/>
        फैंसी में केवल टोटल बॉलर दुवारा दिए गए रन ही मान्य होंगे। टीम के लेग बाई और बाई बॉलर फैंसी में ऐड नहीं किये जायँगे ओनली ओवर रन फैंसी में टोटल उस ओवर में दिए गए रन मान्य होंगे जिसमे एक्स्ट्रास और बैट्समैन दुवारा बनाये गए रन मन्ये होंगे</li>
      </ul>
      <h3>TEST RULES</h3>
   <ul>
    <li>1.1 एडवांस सेशनटेस्ट में मान्य है</li>
    <li>1.2 घोषित पारी या ऑल आउट के कारण मध्य सेशन और सेशन पूरा नहीं हुआ है, इसलिए विशेष ओवर को पूरा माना जाता है और शेष ओवर को अगली टीम पारी में गिना जाता है: - घोषित पारी या ऑल आउट के मामले में 131.5वें ओवर में 132 ओवर माना जाता है मध्य सेशन में 133 के लिए बचे हुए 1 ओवर को पूरा किया गया और अगली टीम की पारी से 135 ओवर के सेशन के लिए 3 ओवर गिने गए और एक ओवर का सेशन घोषित किया गया और पारी घोषित होने के कारण केवल ओवर का सेशन पूरा नहीं हुआ, ताकि सेशन के विशेष दांव को हटा दिया जाएगा और सभी पर विचार किया जाएगा पूर्व के लिए मान्य:- 131.5वें ओवर में घोषित पारी के मामले में</li>
    <li>132 ओवर हटा दिए जाएंगे और यदि ऑल आउट हुए तो 132 ओवर और केवल 132 ओवर ही मान्य होंगे।</li>
    <li>1.6 टेस्ट मैच दोनों इनिंगमें एडवांस फैंसीमान्य हैं।</li>
    <li>2 टेस्ट लंबी/इनिंग रन:-</li>
    <li>2.2 यदि मौसम की स्थिति के कारण मैच रोक दिया गया है तो सभी लंबी फैंसीको हटा दिया जाएगा।</li>
    <li>2.3 टेस्ट में लंबी पारी/इनिंग रन दोनों एडवांस सेशन मान्य हैं।</li>
    <li>3 टेस्ट बल्लेबाज :-</li>
    <li>3.1 बल्लेबाज के चोटिल होने की स्थिति में अगर बल्लेबाज 34 रन पर है तो फाइनल रिजल्ट में 34 रन ही दिए जायँगे</li>
    <li>3.2 बल्लेबाज 50/100 रन परअगर बल्लेबाज घायल हो जाता है रिजल्ट उसके रन पर दिया जाएगा।</li>
    <li>3.3 अगले बैट्समैन आउट फैंसीमेंअगर खिलाड़ी घायल हो जाता है तो एडवांस फैंसी को हटा दिया जाएगा।</li>
    <li>3.4 एडवांस फैंसीमें ओपनिंग बैट्समैन ही मान्य है यदि वही बैट्समैन ओपनिंग में आए तो फैंसी मान्य होगा यदि एक बैट्समैन को बदल दिया जाता है तो उस एडवांस फैंसी को हटा दिया जाएगा।</li>
    <li>3.5 टेस्ट मैच में दोनों एडवांस बैट्समैन फैन्सी मान्य हैं।</li>
    <li>4 टेस्ट पार्टनरशिप:-</li>
    <li>4.1 पार्टनरशिप में एक बल्लेबाज घायल होता है तो अगले बल्लेबाज के साथ पार्टनरशिप जारी रहती है।</li>
  <li>4.2 मौसम की स्थिति के कारण पार्टनरशिप और खिलाड़ी चलता है या मैच रद्द कर दिया जाता है, फाइनल रिजल्ट स्कोर के अनुसार दिया जाएगा।</li>
  <li>4.3 दोनों खिलाड़ियों के अलग या समान होने की स्थिति में पार्टनरशिप मान्य है।</li>
  <li>4.4 टेस्ट मैच में दोनों एडवांस फैंसी पार्टनरशिप मान्य है।</li>
  <li>5 अन्य एडवांस फैंसी (TEST):-</li>
  <li>5.1  चार, छक्के, वाइड, विकेट, अतिरिक्त रन, कुल रन, उच्चतम ओवर और शीर्ष बल्लेबाज तभी मान्य होते हैं जब 300 ओवर खेले गए हों या मैच किसी भी टीम द्वारा जीता गया हो अन्यथा इन सभी फैंसी को हटा दिया जाएगा। इसके अतिरिक्त सभी इवेंट्स केवल पहली पारी के लिए मान्य हैं (यह सभी व्यक्तिगत टीम इवेंट्स पर भी लागू होता है)</li>
   </ul><h3>ODI नियम:-</h3>
   <ul>

  <li>मैच का पहला ओवर रन एडवांस फैंसी केवल पहली पारी के रन गिने जाएंगे।</li>
  <li>बारिश या मैच रद्द होने की स्थिति में कम्प्लीट फैंसी मान्य है, इन्कम्प्लीट फैंसी हटा दिए जाएंगे। और जो पार्टनरशिप, फॉलऑफ विकेट, और खिलाड़ी रनिंग में हैं उनका फाइनल रिजल्ट उनके रनों पर ही दिया जाएगा।</li>
  <li>उदाहरण के लिए: 35 ओवर रन टीम ए किसी भी मामले में खेल रही है, टीम ए 33 ओवर में ऑल-आउट हो गई है, टीम ए ने 150 रन बना लिए हैं, फैंसी फाइनल रिजल्ट उस रन पर मान्य किया जाता है।</li>
  <li>एडवांस फैंसी केवल पहली पारी में मान्य है।</li>
  <li>50 ओवर पूरा नहीं होने की स्थिति में मौसम या किसी भी स्थिति के कारण, सभी बेट हटा दिए जाएंगे।</li>
  <li>एडवांस 50 ओवर रन केवल पहली पारी में मान्य है।</li>
</ul>
<h3>वनडे बल्लेबाज रन :-</h3>
<ul>
  <li>बल्लेबाज के चोटिल होने की स्थिति में अगर बल्लेबाज 34 रन पर है तो फाइनल रिजल्ट में 34 रन ही दिए जाएंगे।</li>
  <li>अगले बैट्समैन आउट फैंसी में अगर खिलाड़ी घायल हो जाता है तो एडवांस फैंसी को हटा दिया जाएगा।</li>
  <li>एडवांस फैंसी में ओपनिंग बैट्समैन ही मान्य है, यदि वही बैट्समैन ओपनिंग में आए तो फैंसी मान्य होगा। यदि एक बैट्समैन को बदल दिया जाता है तो उस एडवांस फैंसी को हटा दिया जाएगा।</li>
  <li>पार्टनरशिप में एक बल्लेबाज घायल होता है तो अगले बल्लेबाज की पार्टनरशिप जारी रहती है।</li>
  <li>दोनों खिलाड़ियों के अलग या समान होने की स्थिति में एडवांस सेशन पार्टनरशिप मान्य है।</li>
  <li>दोनों टीम की एडवांस पार्टनरशिप मैच में मान्य हैं।</li>
  <li>Extra Sessions :-</li>
  <li>चार, छक्के, वाइड, विकेट, अतिरिक्त रन, कुल रन, उच्चतम ओवर, शीर्ष बल्लेबाज, मेडेन ओवर, कैच आउट, नो बॉल, रन आउट, अर्धशतक और शतक मान्य हैं। केवल बारिश के कारण मैच पूरा हो गया है, ओवर कम कर दिया गया है तो अन्य सभी फैंसी हटा दिए जाएंगे।</li>
</ul>
<h3>T20 SESSION-</h3>
<ul>
  <li>T20 SESSION:-</li>
  <li>मैच का पहला ओवर रन एडवांस फैंसी केवल पहली पारी के रन गिने जाएंगे।</li>
  <li>बारिश या मैच रद्द होने की स्थिति में कम्प्लीट फैंसी मान्य है, इन्कम्प्लीट फैंसी हटा दी जाएंगी। और जो पार्टनरशिप, फॉल ऑफ विकेट, और खिलाड़ी रनिंग में हैं, उनका फाइनल रिजल्ट उनके रनों पर ही दिया जाएगा।</li>
  <li>उदाहरण के लिए: - 35 ओवर रन टीम ए किसी भी मामले में खेल रही है, टीम ए 33 ओवर में ऑल-आउट हो गई है, टीम ए ने 150 रन बना लिए हैं, फैंसी फाइनल रिजल्ट उस रन पर मान्य किया जाता है।</li>
  <li>एडवांस फैंसी केवल पहली पारी में मान्य है।</li>
  <li>एडवांस 20 ओवर रन केवल पहली पारी में मान्य है। 20 ओवर का रन मान्य नहीं माना जाएगा यदि 20 ओवर किसी भी स्थिति में पूरा नहीं होता है।</li>
  </ul>

<h3>टी20 बल्लेबाज रन :-</h3>
<ul>
<li>बल्लेबाज के चोटिल होने की स्थिति में अगर बल्लेबाज 34 रन पर है तो फाइनल रिजल्ट में 34 रन ही दिए जाएंगे।</li>
  <li>अगले बैट्समैन आउट फैंसी में अगर खिलाड़ी घायल हो जाता है तो एडवांस फैंसी को हटा दिया जाएगा।</li>
  <li>एडवांस में ओपनिंग बैट्समैन ही मान्य है। अगर वही बैट्समैन ओपनिंग में आए तो फैंसी मान्य होगा। यदि एक बैट्समैन को बदल दिया जाता है तो उस विशेष खिलाड़ी को हटा दिया जाएगा।</li>
  <li>पार्टनरशिप में एक बल्लेबाज घायल होता है तो अगले बल्लेबाज की पार्टनरशिप जारी रहती है।</li>
  <li>दोनों खिलाड़ियों के अलग या समान होने की स्थिति में पार्टनरशिप मान्य है।</li>
  <li>दोनों टीम एडवांस पार्टनरशिप मैच में मान्य हैं।</li>
  <li>एडवांस SESSION केवल पहली पारी में मान्य है।</li>
  <li>अगर बारिश या मौसम की स्थिति के कारण ओवर कम हो जाता है या मैच रद्द हो जाता है तो फाइनल रिजल्ट स्कोर के अनुसार दिया जाएगा।</li>
</ul>

<h3>EXTRA FANCY:-</h3>
<ul>
<li>टी-20, वन डे और टेस्ट मैच में अगर मौजूदा पारी खिलाड़ी और पार्टनरशिप मैच के बीच में चल रही हो तो उसे रद्द कर दिया जाता है या छोड़ दिया जाता है, उस स्थिति में सभी मौजूदा खिलाड़ी और साझेदारी के फाइनल रिजल्ट मान्य होते हैं।</li>
  <li>चौका, छक्का, वाइड, विकेट, अतिरिक्त रन, कुल रन, उच्चतम ओवर और शीर्ष बल्लेबाज, मेडेन ओवर, कैच आउट, नो बॉल, रन आउट, अर्धशतक और शतक मान्य हैं। केवल बारिश के कारण मैच पूरा हो गया है, ओवर कम कर दिया गया है, अन्य सभी फैंसी हटा दिए जाएंगे।</li>
  <li>पहली 6 ओवर डॉट बॉल और 20 ओवर डॉट बॉल फैंसी केवल पहली पारी ही मान्य है।</li>
  <li>किसी भी टीम की गेंदों पर पहला विकेट गंवाने का मतलब है कि किसी भी टीम का पहला विकेट कितनी गेंदों में गिरता है, कम से कम न्यूनतम एक गेंद खेली जानी चाहिए अन्यथा बेट हटा दी जाएगी।</li>
  <li>किसी भी टीम का पहला विकेट FALL दोनों पारियों में मान्य है।</li>
  <li>किसी भी टीम ने 50 रन के लिए कितनी गेंदों का मतलब है कि किसी भी टीम ने 50 रन हासिल किए हैं, उस विशेष गेंद को कितनी गेंदों में, कम से कम एक गेंद खेलनी होगी, अन्यथा वह फैंसी दांव हटा दिया जाएगा।</li>
  <li>50 रन के लिए कितनी गेंदों पर किसी भी टीम को केवल पहली पारी ही मान्य होती है।</li>
  <li>पहली 6 इनिंग बाउंड्री रन किसी भी टीम के फैंसी की गिनती केवल रन बनाए गए चौकों और छक्कों के हिसाब से की जाएगी। कम से कम 6 ओवर खेले जाने चाहिए अन्यथा वह फ़ैंसी हटा दी जाएगी।</li>
  <li>पहली पारी में 6 ओवर की बाउंड्री किसी भी टीम के रन जैसे वाइड, नो-बॉल, लेग-बाय, बाई और ओवर थ्रो के रन इस फैंसी में नहीं गिने जाते हैं।</li>
  <li>किसी भी बल्लेबाज का सामना कितनी गेंदों से होता है मतलब कि कोई भी बल्लेबाज कितनी गेंदों का खेलता है। इसमें कम से कम एक गेंद खेलनी होती है, अन्यथा वह फैंसी दांव हटा दिया जाएगा।</li>
  <li>किसी भी बल्लेबाज द्वारा कितनी गेंदों का सामना करना दोनों पारियों में मान्य है।</li>
  <li>सबसे कम स्कोरिंग ओवर को तभी मान्य माना जाएगा जब ओवर पूरी तरह से पूरा हो गया हो (सभी छह गेंदें फेंकी जानी हैं)।</li>
</ul>

<h3>TEST में कनकशन:-</h3>
<ul>
<li>सेशन अधूरा होने की स्थिति में, एक ओवर सेशन के सभी बेट्स हटा दिए जाएंगे। उदाहरण के लिए पारी की घोषणा या मैच खराब रोशनी या किसी अन्य स्थिति में सस्पेंड हो जाता है।</li>
  <li>सभी बेट्स को मान्य माना जाएगा यदि किसी खिलाड़ी को कनकशन सब्स्टीट्यूट के तहत बदल दिया गया है। रिजल्ट उल्लिखित खिलाड़ी द्वारा बनाए गए रनों के लिए दिया जाएगा। उदाहरण के लिए, डीएम ब्रावो 23 रन पर रिटायर्ड हर्ट हो जाते हैं, तो रिजल्ट 23 के लिए दिया जाएगा।</li>
  <li>कनकशन सब्स्टीट्यूट के तहत दोनों खिलाड़ियों के दांव मान्य होंगे।</li>
</ul>
<h3>Total Match- Events (Test):-</h3>
<ul>
  <li>पूरे टेस्ट मैच में कम से कम 300 ओवर फेंके जाने चाहिए, अन्यथा विशेष इवेंट से संबंधित सभी बेट्स अमान्य हो जाएंगे। उदाहरण के लिए, कैच आउट का टोटल मैच केवल तभी मान्य होगा जब किसी विशेष टेस्ट मैच में 300 ओवर फेंके गए हों।</li>
</ul>

<h3>Limited Over Events- Test:-</h3>
<ul>
  <li>इस इवेंट को केवल तभी मान्य माना जाएगा जब विशेष इवेंट पर परिभाषित ओवरों की संख्या फेंकी गई हो, अन्यथा इस इवेंट से संबंधित सभी दांव शून्य हो जाएंगे। उदाहरण के लिए 0-25 ओवर की घटनाएँ तभी मान्य होंगी जब 25 ओवर फेंके गए हों, अन्यथा उसे मान्य नहीं माना जाएगा।</li>
  <li>यदि टीम किसी भी परिभाषित ओवर से पहले ऑल आउट हो जाती है, तो शेष ओवरों को अगली पारी में गिना जाएगा। उदाहरण के लिए यदि टीम 23.1 ओवर में ऑल आउट हो जाती है तो उसे 24 ओवर माना जाएगा और शेष ओवर अगली पारी से गिने जाएंगे।</li>
</ul>

<h3>Bowler Wicket Events- Test:-</h3>
<ul>
  <li>कम से कम एक ओवर (एक पूर्ण ओवर) गेंदबाज द्वारा फेंका जाना चाहिए, अन्यथा उसे मान्य नहीं माना जाएगा।</li>
</ul>

<h3>Bowler Over Events- Test:-</h3>
<ul>
  <li>उल्लिखित गेंदबाज को निर्धारित ओवरों की संख्या को पूरा करना होगा, अन्यथा उस विशेष इवेंट से संबंधित दांव शून्य हो जाएंगे। उदाहरण के लिए यदि उल्लिखित गेंदबाज ने 8 ओवर फेंके हैं, तो उस विशेष गेंदबाज के 5 ओवर के रन को मान्य माना जाएगा और 10 ओवर के रन को अमान्य कर दिया जाएगा।</li>
</ul>

<h3>Player Ball Events- Test:-</h3>
<ul>
  <li>इस इवेंट को तभी मान्य माना जाएगा जब उल्लिखित खिलाड़ी द्वारा बनाए गए रनों की परिभाषित संख्या पूरी हो, अन्यथा रिजल्ट 0 (शून्य) गेंदों के रूप में माना जाएगा।</li>
  <li>उदाहरण के लिए, यदि रूट 60 गेंदों में 20 रन बनाता है और 22 रन पर आउट हो जाता है, तो 20 रन का रिजल्ट 60 गेंदों का होगा और 25 रन के लिए आवश्यक गेंदों का रिजल्ट 0 (शून्य) माना जाएगा और वही दिया जाएगा नतीजा।</li>
</ul>

<h3>Limited Over Event- One Day:-</h3>
<ul>
  <li>इस इवेंट को केवल तभी मान्य माना जाएगा जब विशेष इवेंट पर परिभाषित ओवरों की संख्या फेंकी गई हो, अन्यथा इस इवेंट से संबंधित सभी दांव शून्य हो जाएंगे। 0-50 ओवर की घटनाएँ केवल 50 ओवर पूरे होने पर ही मान्य होंगी।</li>
  <li>यदि पहले बल्लेबाजी करने वाली टीम 50 ओवर से पहले ऑल आउट हो जाती है, तो शेष ओवर की गणना दूसरी पारी से की जाएगी। उदाहरण के लिए यदि पहले बल्लेबाजी करने वाली टीम 35 ओवरों में ऑल आउट हो जाती है, तो शेष 15 ओवर दूसरी पारी से गिने जाएंगे।</li>
  <li>यदि टीम निर्धारित ओवरों की संख्या से पहले ऑल आउट हो जाती है, तो यह सभी घटनाओं पर लागू होता है।</li>
  <li>यदि मैच में किसी भी स्थिति में ओवर कम हो जाता है, तो अधूरी रहने वाली इवेंट को रद्द कर दिया जाएगा। उदाहरण के लिए यदि बारिश / खराब रोशनी के कारण 15 ओवरों में मैच बाधित हो जाता है और इस ओवर को कम कर दिया जाता है, तो 0-10 के लिए इवेंट मान्य होंगे, इस प्रकार से संबंधित अन्य सभी इवेंट हटा दिए जाएंगे।</li>
  <li>यह इवेंट तभी मान्य होगा जब ओवर की परिभाषित संख्या पूरी हो गई हो।</li>
  <li>उदाहरण के लिए, पहले बल्लेबाजी करने वाली टीम 29.4 ओवर में ऑल आउट हो जाती है तो उसे 30 ओवर माना जाएगा, बाद में बल्लेबाजी करने वाली टीम को 20 ओवर पूरे करने होंगे तभी 0-50 ओवर को मान्य माना जाएगा।</li>
  <li>यदि दूसरी बल्लेबाजी करने वाली टीम 19.4 ओवर में ऑल आउट हो जाती है, तो 0-50 ओवर की इवेंट को मान्य नहीं माना जाएगा, यह केवल पहली पारी के लिए मान्य है।</li>
</ul>
<h3>Bowler Event- ODI:-</h3>
<ul>
  <li>उल्लिखित गेंदबाज को निर्धारित ओवरों की संख्या को पूरा करना होगा, अन्यथा उस विशेष इवेंट से संबंधित दांव शून्य हो जाएंगे। उदाहरण के लिए यदि उल्लिखित गेंदबाज ने 8 ओवर फेंके हैं, तो उस विशेष गेंदबाज के 5 ओवर के रन को मान्य माना जाएगा और 10 ओवर के रन को अमान्य कर दिया जाएगा।</li>
  <li>दोनों पारियां मान्य हैं।</li>
</ul>

<h3>Other Events - T20:-</h3>
<ul>
  <li>1-10 ओवर और 11-20 ओवर की इवेंट को तभी मान्य माना जाएगा जब उल्लिखित ओवर की संख्या पूरी तरह से खेली गई हो। हालाँकि, यदि किसी विशेष इवेंट से पहले ओवर कम हो जाता है तो वह अमान्य हो जाएगा।</li>
  <li>यदि पहले बल्लेबाजी करने वाली टीम 20 ओवर से पहले ऑल आउट हो जाती है, तो शेष ओवर की गणना दूसरी पारी से की जाएगी। उदाहरण के लिए, यदि पहले बल्लेबाजी करने वाली टीम 17 ओवर में ऑल आउट हो जाती है, तो शेष 3 ओवर दूसरी पारी से गिने जाएंगे और सभी घटनाओं के 3 ओवर गिने जाएंगे। यह केवल पहली पारी के लिए मान्य है।</li>
  <li>यदि किसी रनिंग इवेंट के बीच ओवर कम हो जाता है, तो उसे मान्य माना जाएगा और बाकी को रद्द कर दिया जाएगा।</li>
  <li>उदाहरण के लिए, मैच शुरू हुआ और बारिश/खराब रोशनी या किसी अन्य स्थिति के कारण मैच 4 ओवर में बाधित हो गया और बाद में ओवर कम हो गया। फिर 1-10 ओवर के इवेंट मान्य हैं, बाकी सभी रद्द कर दिए जाएंगे।</li>
</ul>

<h3>Bowler Session:-</h3>
<ul>
  <li>बॉलर सेशन एडवांस इवेंट केवल पहली इनिंग के लिए मान्य है। यह इवेंट तभी मान्य होती है जब गेंदबाज ने अपने ओवरों का अधिकतम कोटा पूरा कर लिया हो, अन्यथा उसे रद्द कर दिया जाएगा।</li>
  <li>हालाँकि, यदि मैच का रिजल्ट आ गया है और उस गेंदबाज ने पहले ही अपना अंतिम ओवर फेंकना शुरू कर दिया है, तो रिजल्ट दिया जाएगा भले ही उसने ओवर पूरा नहीं किया हो।</li>
  <li>उदाहरण के लिए, यदि बी कुमार अपना अंतिम ओवर फेंक रहे हैं और 3.4 ओवर पर मैच का रिजल्ट आया है, तो रिजल्ट बी कुमार के ओवर रन के लिए दिया जाएगा।</li>
</ul>

<h3>DLS के मामले में:-</h3>
<ul>
  <li>यदि ओवर कम हो गया है, तो जिस गेंदबाज ने पहले से ही उस रिजल्ट का अपना अधिकतम कोटा डाला है, उसे मान्य माना जाएगा और बाकी को रद्द कर दिया जाएगा।</li>
</ul>

<h3>डॉट बॉल इवेंट</h3>
    <ul>
        <li>केवल कोई रन नहीं होने पर डॉट बॉल मानी जाएगी।</li>
        <li>अगर विकेट गिरता है, तो डॉट बॉल नहीं गिना जाएगा।</li>
        <li>फ्री हिट पर बाउंड्री हिट को ही मान्य माना जाएगा।</li>
        <li>उल्लिखित मैच में कोई फ्री हिट नहीं होने पर बेट्स को हटा दिया जाएगा।</li>
        <li>बल्ले से बाउंड्री मान्य मानी जाएगी।</li>
        <li>चार और छह दोनों मान्य हैं।</li>
        <li>बल्लेबाज की बैट बाउंड्री को ही मान्य माना जाता है।</li>
        <li>फ्री हिट सीमाएं भी मान्य हैं।</li>
        <li>यदि उस गेंद को पूरा नहीं किया जाता है, तो बेट्स को रद्द कर दिया जाएगा।</li>
        <li>रिजल्ट 0 या 4 (नहीं या हां) दिया जाएगा। उदाहरण के लिए, बल्लेबाज गेंद पर चौका मारने का मतलब रिजल्ट 0 है, अन्यथा रिजल्ट 4 है।</li>
    </ul>

    <h3>बिग बैश में पावर सर्ज नियम</h3>
    <ul>
        <li>पावर प्ले पहले चार ओवर + पावर सर्ज दो ओवर-बल्लेबाज पसंद।</li>
        <li>बल्लेबाजी पक्ष चुनता है कि पावर सर्ज के साथ कब करना है।</li>
        <li>पारी की शुरुआत में अभी भी चार ओवर का पावर प्ले है, लेकिन अब बल्लेबाजी करने वाली टीम 11वें ओवर के बाद से किसी भी समय अन्य दो पावर सर्ज ओवर ले सकती है।</li>
    </ul>

    <h3>रिजल्ट और दर के बारे में संपर्क</h3>
    <ul>
        <li>रिजल्ट या दर के बारे में किसी भी प्रश्न के लिए इवेंट से 4 दिनों के भीतर संपर्क करना होगा।</li>
        <li>इवेंट से 4 दिनों के बाद संपर्क मान्य नहीं माना जाएगा।</li>
    </ul>

    </div>
    <MainCommanButton text={"MAIN MENU"} onClick={handleClick}/>

    </>
  );
};

export default InstructionsPage;
