import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';
import Header from '../common/header';
import  AppFooter  from '../common/footer';

const DefaultLayout = () => {
    return (
        <>
            <div style={{ overflow: 'hidden' }}>
                <Header />
                <div className='layout' >
                    <Outlet>
                    </Outlet>
                </div>
              <AppFooter/>
            </div>
        </>
    )
}

export default DefaultLayout

