import React from 'react';
import { Avatar, Typography, Paper } from '@mui/material';
import { BackButton } from '../common/backbutton';
import { useNavigate } from 'react-router-dom';
import { home } from '../../routesconfig/constant';
import { useSelector } from 'react-redux';
import profile from '../../assets/images/profile.jpg'

const ProfilePage = () => {
  const navigate = useNavigate();
  const userDetails = useSelector(state => state.auth.userdetail);

  if (!userDetails) {
    return <p>Loading...</p>;
  }

  const handleClick = () => {
    navigate(home);
  }

  return (
    <>
      <BackButton onClick={handleClick} />
      <Paper className="profile-card" elevation={3}>
        <Avatar
          alt={userDetails.name}
          sec={profile} // Replace with actual image URL or import
          className="avatar"
        />
        <Typography variant="h6" className="name">
          {userDetails.name}
        </Typography>
        <Typography variant="body2 h5" color="textSecondary" className="location">
          Total Coins
        </Typography>
        <Typography variant="body2" color="textSecondary" className="location">
          {userDetails.chips || 0}
        </Typography>
        <div className="stats">
          <div className="stat-item">
            <Typography variant="body2 h6">Client Code:</Typography>
            <Typography variant="h6">{userDetails.code || "C"}</Typography>
          </div>
          <div className="stat-item">
            <Typography variant="body2 h6">Client Name:</Typography>
            <Typography variant="h6">{userDetails.name}</Typography>
          </div>
          <div className="stat-item">
            <Typography variant="body2 h6">Contact No:</Typography>
            <Typography variant="h6">{userDetails.contectNo}</Typography>
          </div>
          <div className="stat-item">
            <Typography variant="body2 h6">Date Of Joining</Typography>
            <Typography variant="h6">July 25, 2024</Typography>
          </div>
          <div className="stat-item">
            <Typography variant="body2 h6">Address:</Typography>
            <Typography variant="h6">India</Typography>
          </div>
        </div>
      </Paper>
      <BackButton onClick={handleClick} />
    </>
  );
};

export default ProfilePage;
