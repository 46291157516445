import React, { useState } from 'react';
import { BackButton } from '../common/backbutton';
import { casino, home } from '../../routesconfig/constant';
import { useNavigate } from 'react-router-dom';

const  CoinFlip =()=> {
  const navigate = useNavigate()
  const [result, setResult] = useState(null);
  const [flipping, setFlipping] = useState(false);

  const flipCoin = () => {
   
    setFlipping(true);
    setTimeout(() => {
      const random = Math.random();
      const flipResult = random < 0.5 ? 'Heads' : 'Tails';
      setResult(flipResult);
      setFlipping(false);
    }, 1000); // Simulate coin flipping for 1 second
  };

  const handleClick = () =>{
  navigate(casino)
}
  return (
  <>
  <BackButton onClick={handleClick}/>
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      
      <h1 className='mt-2'>Flip a Coin</h1>
     
          <button className='mt-2' onClick={flipCoin} disabled={flipping}>
        {flipping ? 'Flipping...' : 'Flip Coin'}
      </button>
      
  <div className='cmd mt-3'>   
       <div className='cmd' style={{height:'120px', width:"120px", backgroundColor:'goldenrod', borderRadius:'100px'}}>  
      {result && <h2 className='mt-2'>{result}</h2>}
      </div>
      </div>

      <h1 className='mt-2'>Beting Coming soon....</h1>
    </div>
    </>
  );
}

export default CoinFlip;
