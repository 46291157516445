import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";


import CommanTable from "../common/commontable";
import { GetAccountStatement, UseAccountStatement, UseLedgerData } from "../../api/login/useLogin";
import { OperationType } from "../models";
import { Calendar } from "../common/calender";
import { formatDate } from "../../service/utilities/dateFormater";
import { BackButton } from "../common/backbutton";
import { home } from "../../routesconfig/constant";
const MyLedger = () => {
  const navigate = useNavigate();

  const accountid = JSON.parse(sessionStorage.getItem("account") || "{}");
  const userId = sessionStorage.getItem("userid");

  const { submit: getledgerData } = UseLedgerData()

  const [dateObj, setDateObj] = useState({ startDate: null, endDate: null });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [agenttabledata, setAgentTabledata] = useState();
  const [totalDataLength, setTotalDataLength] = useState(false);



  const paginationdata = {
      searchKey: "",
      sortKey: "",
      sortDirection: "",
      itemsPerPage: rowsPerPage,
      page: page + 1,
 
  };

  const fatchStatementData = () => {

    getledgerData(paginationdata, (response) => {
      const agenttabledata = response?.data?.records?.map(
        (res, index) => ({
        
          ...res,
          createdDate: formatDate(res.createdDate),
        
          debit: res.amount < 0 ? res.amount : null,
          credit: res.amount > 0 ? res.amount : null
          
        })
      );
      setAgentTabledata(agenttabledata);
      setTotalDataLength(response?.data?.totalRecords);
    });
  }
  useEffect(() => {
    if (accountid) {
      fatchStatementData()
    }
    console.log(rowsPerPage, page)
  }, [ page, rowsPerPage, dateObj]);

  // useEffect(() => {
  //   if (dateObj?.startDate && dateObj?.endDate) {
  //     fatchStatementData()
  //   }
  // }, [dateObj])



  const columns = [
    // { id: "date", label: "Date", minWidth: 120, align: "center" },
    { id: "eventName", label: "Event Name", minWidth: 300, align: "center" },
    { id: "winner", label: "Won By", minWidth: 120, align: "center" },
    { id: "ledgerType", label: "Ledger Type", minWidth: 60, align: "center" },
    { id: "debit", label: "Debit", minWidth: 60, align: "center" },
    { id: "credit", label: "Credit", minWidth: 60, align: "center" },
    // { id: "amount", label: "Amount", minWidth: 60, align: "center" },
    { id: "balance", label: "Balance", minWidth: 120, align: "center" },
    { id: "createdDate", label: "Settlement Date", minWidth: 120, align: "center" },
  ];


  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    debugger
    setRowsPerPage(parseInt((event?.target?.value || 10), 10));
    setPage(0); // Reset to first page whenever rows per page changes
  };

  const onDateChange = (dateObject) => {
    setDateObj(dateObject);
    handleRowsPerPageChange();
  };

  const handleClick = () => {
    navigate(home);
  };

const tabletool = () =>{

}


  return (
    <>
      <BackButton onClick={handleClick} />
      <div className="form-box mt-2" style={{}}>
 
        <div className="mt-2">
          <CommanTable
            title={"My Ledger"}
            columns={columns}
            rows={agenttabledata}
            totalDataLength={totalDataLength}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            tabletool={tabletool}
          />
        </div>
      </div>
    </>
  );
};

export default MyLedger;


