import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import enIN from "date-fns/locale/en-IN";

export const Calendar = (props) => {
  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const formatDateInIST = (date) => {
    if (!date) return ""; // Handle null case
    const istDate = new Date(date.getTime() + 5.5 * 60 * 60 * 1000); // Add 5 hours 30 minutes
    return format(istDate, "yyyy-MM-dd'T'HH:mm:ss"); // Format the date
  };

  const updateDates = (item) => {

    setDate([item?.selection]);
    props?.onDateChange({ startDate: formatDateInIST(item?.selection?.startDate), endDate: formatDateInIST(item?.selection?.endDate) });
  };

  const hasValidDates = date[0]?.startDate && date[0]?.endDate;

  return (
    <div className="headerSearchItem">
      <CalendarMonthIcon />
      <span
        onClick={() => setOpenDate(!openDate)}
        className="headerSearchText"
      >
        {hasValidDates ? format(date[0].startDate, "MM/dd/yyyy") : "Start Date"}
        <span className="p-3">To</span>
        {hasValidDates ? format(date[0].endDate, "MM/dd/yyyy") : "End Date"}
      </span>
      {openDate && (
        <div style={{ position: 'fixed', zIndex: '999999' }} onMouseLeave={() => {
          setOpenDate(false)
        }}>
          <DateRange
            editableDateInputs={false}
            onChange={updateDates}
            moveRangeOnFirstSelection={false}
            locale={enIN}
            ranges={hasValidDates ? date : [{
              startDate: new Date(),
              endDate: new Date(),
              key: "selection",
            }]}
          />
        </div>
      )}
    </div>
  );
};
