import React from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';

const CustomCard = ({ heading, title, datetime }) => {
  return (
    <Card className="mb-3 mt-3">
      <div className="card-header text-center" style={{backgroundColor:'#643760',color:'white', fontWeight:'bold'}}>
        {heading}
      </div>
      <CardBody className="text-center card-body">
        <CardTitle tag="h5" style={{color:'#563253', margin:'10px 10px'}}>{title}</CardTitle>
        <hr/>
        <CardText>
          <ul className='inplaycardDetails'>
           
              <li>{datetime}</li>

          </ul>
        </CardText>
      </CardBody>
    </Card>
  );
};

export default CustomCard;
 