import { useDispatch } from "react-redux";
import { useGetAPI } from "../../service/apiServices";
import { GetCricketOdds, GetCricketSassion } from "../../service/apiconst";

export const useMatchSessionLive = (id) => {
    const dispatch = useDispatch();

    return useGetAPI({
        contenttype: false,
        url: GetCricketOdds(id),
        autoRunStop: false,
        dispatch: (e) => {
            // dispatch(e);
        },
    });
}
