import React from 'react'
const AppFooter = () =>{

    return<>
    <div className="footer cmd">
<span>Copyright ©Bingobet247.net 2024.</span>
    </div>
    </>
}
export default AppFooter
