import React from "react";

const CompletedBetList = ({ columns, listData,betlist}) => {
  return (
    <div className="teenpatti-betlist-container">
      <table className="table table-bordered text-center mb-0">
        <thead>
          <tr>
            {columns?.map((column) => (
              <th>{column?.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {listData?.map((row, index) => (
            <tr         
         
            key={index}>
              {columns?.map((column) => (
                <td style={{
                  backgroundColor: row?.win
                  ? "#a6ef64"
                  : "#ff8174",
                }}>{row[column.id]}</td>
             
              ))}
            </tr>
          ))}
        </tbody>
      </table>
  
  
    </div>
  );
};

export default CompletedBetList;
