import { useState, useEffect } from 'react';

const FlipCountdown = ({ endAt }) => {
  const [current, setCurrent] = useState(endAt);
  const [previous, setPrevious] = useState(endAt);
  const [flipping, setFlipping] = useState(false);

  useEffect(() => {
    if (endAt !== current) {
      setPrevious(current);
      setCurrent(endAt);
      setFlipping(true);
    }
  }, [endAt, current]);

  useEffect(() => {
    if (flipping) {
      const timer = setTimeout(() => setFlipping(false), 600);
      return () => clearTimeout(timer);
    }
  }, [flipping]);

  return (
    <div className="flip-countdown">
      <div className="card-line" />
      <div className="flip-card">

        <div className="top">
          <span> {(current === 0 || !current) ? '00' : current < 10 ? `0${current}` : `${current}`}</span>
        </div>

        {/* <div className={`bottom ${flipping ? 'flipping' : ''}`}>
          <span>{previous < 10 ? `0${previous}` : previous}</span>
        </div>
        
        <div className={`bottom-flip ${flipping ? 'flipping' : ''}`}>
          <span>{current < 10 ? `0${current}` : current}</span>
        </div> */}
      </div>
    </div>
  );
};

export default FlipCountdown;


// import { useState, useEffect } from 'react';

// const FlipCountdown = ({ endAt }) => {
//   const [current, setCurrent] = useState(endAt);
//   const [previous, setPrevious] = useState(endAt);
//   const [flipping, setFlipping] = useState(false);

//   useEffect(() => {
//     // Only update if the new endAt is different
//     if (endAt !== current) {
//       setPrevious(current);
//       setCurrent(endAt);
//       setFlipping(true);
//     }
//   }, [endAt, current]);

//   useEffect(() => {
//     if (flipping) {
//       const timer = setTimeout(() => setFlipping(false), 600);
//       return () => clearTimeout(timer);
//     }
//   }, [flipping]);

//   return (
//     <div className="flip-countdown">
//       <div className="card-line" />
//       <div className="flip-card">
//         <div className="top">
//           <span>{current.length === 1 ? `0${current}` : current}</span>
//         </div>
//         <div className={`bottom ${flipping ? 'flipping' : ''}`}>
//           <span>{previous.length === 1 ? `0${previous}` : previous}</span>
//         </div>
//         <div className={`bottom-flip ${flipping ? 'flipping' : ''}`}>
//           <span>{current.length === 1 ? `0${current}` : current}</span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FlipCountdown;
