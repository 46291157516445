// Dropdown.js


import { styled } from '@mui/material/styles';
import { Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Switch, TextField } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { ClockIcon, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { makeStyles } from '@mui/system';
import Calendar from '@mui/icons-material/Event';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';




const MyTextField = styled(TextField)(({ theme }) => ({
    fontSize: '14px', // Adjust the font size as needed
    '& .MuiInputLabel-root': {
        fontSize: '14px', // Adjust label font size
    },
}));

const Dropdown = ({ field, className }) => {

    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        field?.onChange(newValue, field);
    };


    return (
        <FormControl className={className ? className : 'mt-3'} sx={{ width: '100%', fontSize: '13px' }} size="small"

            error={field?.required && field?.error && !field?.disable}
        >
            <InputLabel id={`label-${field?.label}`}>{field?.label}</InputLabel>
            <Select
                // className='select'
                labelId={`label-${field?.label}`}
                id={`select-${field?.label}`}
                name={field?.label}
                value={field?.value}
                label={field?.label}
                required={field?.required && !field?.disable}
                disabled={field?.disable}
                onChange={(e) =>
                    handleSelectChange(e)
                }

            >
                {
                    field?.item && field?.item.map((item, index) => {
                        return <MenuItem
                            key={index}
                            value={item?.value ? item?.value : item?.name ? item?.name : item}
                        >
                            {item?.name ? item?.name : item}
                        </MenuItem>
                    })
                }

            </Select>
            {/* <FormHelperText>{field.error && field.required && field.helperText}</FormHelperText> */}
        </FormControl>
    );
};
export default Dropdown;

export const InputField = ({ field, className, style }) => {
    const handleInputChange = (event) => {
        const newValue = event.target.value;

        if (field.type === 'number' && newValue < 0 || field.type === 'number' && field.value === ('' || 0 || null || undefined) && newValue === 0) {
            return false;
        } else {
            field?.onChange(newValue, field);

        }
    };

    return (
        <MyTextField
            className={className ? className : 'mt-3'}
            sx={{ width: '100%', fontSize: '13px' }} fullWidth size="small"

            id={field.id}
            label={field.label}
            value={field.value}
            error={!field?.disable && field.required && field.error}
            // helperText={field.error && field.required && field.helperText}
            required={field.required}
            disabled={field?.disable}
            type={field.type}
            max={field.max}
            onChange={(e) => handleInputChange(e)}
            onBlur={(e) => { field?.onBlur?.(e) }}

        />
    );
};



export const DateField = ({ field, className, format }) => {


    const handleInputChange = (value) => {
        field?.onChange(value, field);
    };

    return (
        <div className='mt-2 w-100'>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DemoContainer components={['DatePicker']}  >
                    <DemoItem>
                        <DatePicker disabled={field?.disable || false} className='date-input' label={field?.label} sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: field.error ? 'red' : '',
                                    color: field.error ? 'red' : '',
                                }
                            }
                        }}
                            value={field.value ? dayjs(field?.value) : null}
                            onChange={(date) => handleInputChange(format ? date?.format(format) : date?.format('YYYY/MM/DD'))}
                        />
                    </DemoItem>
                </DemoContainer>
            </LocalizationProvider>
        </div>

    );
};


export const DateCompare = () =>{
    return<>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DemoContainer components={['SingleInputDateRangeField']}>
      <DateRangePicker
        slots={{ field: SingleInputDateRangeField }}
        slotProps={{ textField: { InputProps: { endAdornment: <Calendar /> } } }}
      />
    </DemoContainer>
  </LocalizationProvider>
  </>
}





export const InputFieldNumber = ({ field, className, style }) => {
    const handleInputChange = (event) => {
        const newValue = event.target.value;

        if ((field.type === 'number' && (newValue < 0 || newValue > 100)) || 
            (field.type === 'number' && (field.value === '' || field.value === 0 || field.value === null || field.value === undefined) && newValue === 0)) {
            return false;
        } else {
            field?.onChange(newValue, field);
        }
    };

    return (
        <MyTextField
            className={className ? className : 'mt-3'}
            sx={{ width: '100%', fontSize: '13px' }} fullWidth size="small"

            id={field.id}
            label={field.label}
            value={field.value}
            error={!field?.disable && field.required && field.error}
            // helperText={field.error && field.required && field.helperText}
            required={field.required}
            disabled={field?.disable}
            type={field.type}
            max={field.max}
            onChange={(e) => handleInputChange(e)}
            onBlur={(e) => { field?.onBlur?.(e) }}

        />
    );
};
