const HeaderNotify = () =>{
return<>
<header className="notification-header">
        <div className="notification-text">
          प्रिय ग्राहक एडवांस सेशन और टॉस का रेट चालू है धन्यवाद !
        </div>
      </header>
</>

}
export default HeaderNotify