import card1 from "../../assets/images/cardimage/1.jpg";
import card2 from "../../assets/images/cardimage/2.jpg";
import card3 from "../../assets/images/cardimage/3.jpg";
import card4 from "../../assets/images/cardimage/4.jpg";
import card5 from "../../assets/images/cardimage/5.jpg";
import card6 from "../../assets/images/cardimage/6.jpg";
import card7 from "../../assets/images/cardimage/7.jpg";
import card8 from "../../assets/images/cardimage/8.jpg";
import card9 from "../../assets/images/cardimage/9.jpg";
import card10 from "../../assets/images/cardimage/10.jpg";
import cardJ from "../../assets/images/cardimage/11.jpg";
import cardQ from "../../assets/images/cardimage/12.jpg";
import cardK from "../../assets/images/cardimage/13.jpg";
import heart from "../../assets/images/cardimage/heartcard.png";
import shade from "../../assets/images/cardimage/shadecard.png";
import diamond from "../../assets/images/cardimage/diamondcard.png";
import check from '../../assets/images/check.png'
import club from "../../assets/images/cardimage/clubcard.png";
import { useEffect, useRef, useState } from "react";
import { casino, home } from "../../routesconfig/constant";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../common/backbutton";
import LockIcon from "@mui/icons-material/Lock";
import { Cassinoifram } from "./gameIframe/casinoifram";
import moment from 'moment-timezone';
import SubmitCasinoBet, {
  FetchUserDetails,
  GetcasinoBetList,
  GetcasinoData,
  GetcasinoResult,
} from "../../api/cassino/cassinogame";
import { teenpattilive } from "../../service/model";
import PlacebetCasino from "../common/placebetcasino";
import { BetSectionDialog, ConfirmBetDialog } from "../common/dialog";
import FlipCountdown from "../common/flipcountdown";
import { useDispatch } from "react-redux";
import { setUserDetail } from "../../store/feature/authSlice";
import { formatDate } from "../../service/utilities/dateFormater";

import io from 'socket.io-client';
import CompletedBetList from "./utilities/casinoTable";
import { pollingValue } from "../../service/utilities/poolingutils";

const LuckySeven = () => {
  const dispatch = useDispatch();
  const welcomeRef = useRef(null);

  const scrollToContent = () => {
    if (welcomeRef.current) {
      welcomeRef.current.scrollIntoView({
        behavior: "smooth",
        block: 'start',
        inline: 'nearest'

      });
    }
  }
  const navigate = useNavigate();


  const [lucky7data, setLucky7data] = useState([]);

  const [placebet, setPlacebet] = useState(false);
  const [stakeamount, setStakeAmount] = useState("")
  const [confirmbet, setBetConform] = useState(false);
  const [betdata, setbetdata] = useState([]);
  const [Lucky7Result, setLuckyResult] = useState([]);



  

  const SOCKET_SERVER_URL = "https://automaticapiserver.bbet247.net";
  const PASS_YOUR_EVENT_ID = "lucky7"; // Replace with your actual event ID




  useEffect(() => {
    if(!pollingValue){
    
 
    
        const socket = io(SOCKET_SERVER_URL, { transports: ["websocket"] });
        socket.on("connect", () => {

          socket.emit("addOnEVENT", PASS_YOUR_EVENT_ID);
        });
    
        socket.on("updatedData", (data) => {
          // Do work with your data
   
          setLucky7data(data.data);
          setLuckyResult(data.data.result)
        });
    
         // Error handling
      socket.on("connect_error", (err) => {
        console.error("Connection error:", err);
      });
    
      socket.on("error", (err) => {
        console.error("Socket error:", err);
      });
    
    
    
        // Cleanup function to disconnect the socket when the component unmounts
        return () => {
          socket.disconnect();
        };
      }
    {
    
        const interval = setInterval(casinoRefetch, 1000);
        return () => clearInterval(interval);
    }
    
      }, []);


  const betConform = () => {
    setBetConform(true);
  };

  const handleClose = () => {
    setBetConform(false);
    setPlacebet(false)
  };

  const betprofit = stakeamount * (betdata?.rate - 1);
  useEffect(() => {
    // const intervalId = setInterval(casinoRefetch, 1600);
    // const interval2Id = setInterval(casinoResultrefetch, 3000);
    // const intervalprofile = setInterval(Refetchprofile, 5000);
    return () => clearInterval();
  }, []);


 

  const { response: responseprofile, refetch: Refetchprofile } = FetchUserDetails();
  const userdetails = responseprofile?.data;

  dispatch(setUserDetail(userdetails));

  const { response: casinoResestList, refetch: casinoRefetchList } = GetcasinoBetList("lucky7");
  const betlist = casinoResestList?.data || []


  const completedBetList = casinoResestList?.data
    ?.filter((res) => res.completed)
    ?.map((res) => ({
      ...res,
      createdDateTime: formatDate(res?.createdDateTime),
    }));

    const notCompletedBetList = casinoResestList?.data
    ?.filter((res) => !res.completed)
    ?.map((res) => ({
      ...res,
   
    }));


  // const { response: lucky7result, refetch: casinoResultrefetch } = GetcasinoResult("lucky7");

  // const Lucky7Result = lucky7result?.data || [];

  const { response: luckydata7, refetch: casinoRefetch } = GetcasinoData("lucky7");

  const  luckyData7 = luckydata7?.data

const luckyData = pollingValue ? luckyData7 : lucky7data;

useEffect(() => {
  if (luckydata7) { 
    setLuckyResult(luckydata7?.data?.result);
  }
}, [luckydata7]);


  const t1 = luckyData?.data?.t1;
  const timmer = t1 && t1[0]?.autotime;
  const gtype = t1 && t1[0]?.gtype
  const roundid = t1 && t1[0]?.mid
  const tableroundId = roundid && roundid.toString().split('.')[1] || "0"

  const cardBlock = teenpattilive.find((item) => item.value === "1")?.card;
  const [ Card1,setC1] = useState(card1)
  // const C1 = teenpattilive.find((card) => t1 && card.value === t1[0].C1)?.card ?? card1;



const C1Value = t1 && t1[0].C1
console.log(C1Value)
useEffect(()=>{
  updateCardValue()
},[C1Value])
const updateCardValue = async () => {
  console.log("notdelay",C1Value)
  if (C1Value) {
    const matchingCard = teenpattilive.find((card) => card.value === C1Value);   
    if (matchingCard) {
      if (matchingCard.value.length > 2) {
        // Delay for 2 seconds if value length > 1
        await new Promise((resolve) => setTimeout(resolve, 2500));
      }    
      // Save the card after delay or immediately if no delay
      console.log("notdelay",matchingCard.card)
      const C1 = matchingCard.card ?? cardBlock; // Default to 'Blockcard' if undefined


      setC1(C1);

    }
  }
}










  const datat2 = luckyData?.data?.t2;







  // Declare variables with `let` outside the block
  let t2Low,
    t2High,
    t2Even,
    t2Odd,
    t2Red,
    t2Black,
    t2Card1,
    t2Card2,
    t2Card3,
    t2Card4,
    t2Card5,
    t2Card6,
    t2Card7,
    t2Card8,
    t2Card9,
    t2Card10,
    t2CardJ,
    t2CardQ,
    t2CardK;

  if (Array.isArray(datat2)) {
    // Assign values inside the block without redeclaring
    t2Low = datat2[0];
    t2High = datat2[1];
    t2Even = datat2[2];
    t2Odd = datat2[3];
    t2Red = datat2[4];
    t2Black = datat2[5];
    t2Card1 = datat2[6];
    t2Card2 = datat2[7];
    t2Card3 = datat2[8];
    t2Card4 = datat2[9];
    t2Card5 = datat2[10];
    t2Card6 = datat2[11];
    t2Card7 = datat2[12];
    t2Card8 = datat2[13];
    t2Card9 = datat2[14];
    t2Card10 = datat2[15];
    t2CardJ = datat2[16];
    t2CardQ = datat2[17];
    t2CardK = datat2[18];
  }

  const cardsimgnew = [
    { name: card1, value: "Card 1", data: t2Card1 || "" },
    { name: card2, value: "Card 2", data: t2Card2 || "" },
    { name: card3, value: "Card 3", data: t2Card3 || "" },
    { name: card4, value: "Card 4", data: t2Card4 || "" },
    { name: card5, value: "Card 5", data: t2Card5 || "" },
    { name: card6, value: "Card 6", data: t2Card6 || "" },
    { name: card7, value: "Card 7", data: t2Card7 || "" },
    { name: card8, value: "Card 8", data: t2Card8 || "" },
    { name: card9, value: "Card 9", data: t2Card9 || "" },
    { name: card10, value: "Card 10", data: t2Card10 || "" },
    { name: cardJ, value: "Card J", data: t2CardJ || "" },
    { name: cardQ, value: "Card Q", data: t2CardQ || "" },
    { name: cardK, value: "Card K", data: t2CardK || "" },
  ];

 
  useEffect(() => {
    if (t2Red?.gstatus === "0") {
      handleClose()
    }
  }, [luckyData])


  const { response, loading, submit } = SubmitCasinoBet();

  const betSubmitConform = () => {



    const data = {
      gameType: gtype,
      roundId: betdata?.mid,
      rate: betdata?.rate,
      amount: stakeamount,
      selection: betdata?.nation,
    };
    if (data.amount >= 100 && data.amount <= 25000) {
      submit(data, (response) => {
        if (response?.metaData?.resultMessage === "Success") {
          casinoRefetchList();
          betConform();
          Refetchprofile()
          setStakeAmount("")
          handleClose()
        }
      });
    }
  };



  const betReset = () => {
    setStakeAmount("")
  }

  const lucky7cardList = [
    { t2Card1 },
    { t2Card2 },
    { t2Card3 },
    { t2Card4 },
    { t2Card5 },
    { t2Card6 },
    { t2Card7 },
    { t2Card8 },
    { t2Card9 },
    { t2Card10 },
    { t2CardJ },
    { t2CardQ },
    { t2CardK },
  ];

  // Click handler to find and log all related objects
  const handleCardClick = (cardValue) => {
    const matchedCards = lucky7cardList.filter((cardObj) => {
      const cardKey = Object.keys(cardObj)[0]; // Get the key (e.g., t2Card1)
      return cardObj[cardKey].nation === cardValue;
    });

    if (matchedCards.length > 0) {

    } else {

    }
  };
  const handelPlacebet = (value) => {
    scrollToContent()
    setPlacebet(true)
    setbetdata(value)
  }

  const handlingbet = (value) => {
    setStakeAmount(value);

  };
  const getISTDate = () => {
    return moment().tz('Asia/Kolkata').format('DD-MM-YYYY');
  };

  const getISTTime = () => {
    return moment().tz('Asia/Kolkata').format('HH:mm:ss');
  };


  const handleClick = () => [navigate(casino)];

  const checkProfitLoss = (dataList) => {

    let data = {
      totalAmount: 0,
      winningAmount: 0,
      lossAmount: 0,
      profitLossAmount: 0,
      isProfit: true
    }

    data.totalAmount = dataList
      .filter(bet => bet.completed)
      .reduce((total, bet) => total + bet.amount, 0);

    data.winningAmount = dataList
      .filter(bet => bet.completed && bet.win)
      .reduce((total, bet) => total + bet.profitAndLoss, 0);

    data.lossAmount = dataList
      .filter(bet => bet.completed && !bet.win)
      .reduce((total, bet) => total + bet.profitAndLoss, 0);

    data.isProfit = data.winningAmount >= (-data.lossAmount) ? true : false

    data.profitLossAmount = data.winningAmount + (data.lossAmount)
    // ? data.winningAmount - data.lossAmount
    // : data.lossAmount - data.winningAmount



    return data
  }
  useEffect(() => {
    if (timmer === "20") {
      Refetchprofile()
      casinoRefetchList();
    }
  }, [timmer])


const columns = [
  { id: "selection", label: "Bet" },
  { id: "rate", label: "Rate" },
  { id: "amount", label: "Amount" },
  { id: "result", label: "Result" },
  { id: "createdDateTime", label: "Date & Time" },
  { id: "profitAndLoss", label: "P/L" },
];


const columnsopenbet = [
  { id: "selection", label: "Match Bet" },
  { id: "rate", label: "Odds" },
  { id: "amount", label: "Stake" },

];

  return (
    <>

  
      {
        t2Red?.gstatus !== "0" &&

        <BetSectionDialog
          onClose={handleClose}
          open={placebet}
          content={
            <PlacebetCasino
              placebet={placebet}
              setPlacebet={setPlacebet}
              setStake={setStakeAmount}
              stake={stakeamount}
              odds={betdata && betdata.rate}
              betnation={betdata && betdata.nation}
              betprofit={betprofit}
              handlingbet={handlingbet}
              betReset={betReset}
              onClose={handleClose}
              betSubmitConform={betSubmitConform}
            />
          }
        />
      }

      <div style={{ margin: '10px 0px' }}>
        <BackButton onClick={handleClick} />
      </div>

      <div className="luckey-seven">
        <div className="row">
          <div className="col-md-8">
            <div className="luckyseven">
              <div className="teenpatti-header">

                <div className='cmc'>
                  <span style={{ fontWeight: '500', marginRight: '10px', fontSize: "14px" }}>
                    LUCKY 7
                  </span>
                  <span style={{ fontSize: "14px" }}>
                    Round ID: {tableroundId || 0} <br />
                  </span>
                </div>
                <div className='cmc' style={{ fontSize: '14px' }}>
                  <span style={{ color: '' }} > Date: {getISTDate()}</span>
                  <span style={{ color: '' }} > Time: {getISTTime()}</span>

                </div>

              </div>

              <div className="live_stream">
                <div className="main-websocket">
                  <Cassinoifram id={3058} />
                </div>
                <div className="overflow-card-section">
                  <div className="stream-card-section">
                    <div className="cmd">
                      <img className="card-image" src={Card1} />
                    </div>
                  </div>
                </div>
                <div className="overflow-casino-timer">
                  <FlipCountdown endAt={timmer} />
                </div>
              </div>
              <div
                style={{ margin: "0px" }}
                className="mt-2 row select_bet_box"
              >
                <div className=" col-md-4">
                  <div
                    onClick={() => {
                      t2Low?.gstatus !== "0" && handelPlacebet(t2Low);
                    }}
                    className="tc d-grid"
                  >
                    <span>{t2Low?.rate}</span>
                    <div style={{ position: "relative", width: "100%" }}>
                      <button
                        style={{ width: "100%" }}
                        className="bet_box_even"
                      >
                        LOW CARD
                      </button>
                      {t2Low?.gstatus === "0" && <OverflowContant />}
                    </div>
                    <span>0</span>
                  </div>
                
                </div>
                <div className=" col-md-4 cmd mb-3">
                  <img className="card-image" src={card7} />
                </div>
                <div className=" col-md-4">
                  <div
                    onClick={() => {
                      t2High?.gstatus !== "0" && handelPlacebet(t2High);
                    }}
                    className="tc d-grid"
                  >
                    <span>{t2High?.rate}</span>
                    <div style={{ position: "relative", width: "100%" }}>
                      <button
                        style={{ width: "100%" }}
                        className="bet_box_even"
                      >
                        HIGH CARD
                      </button>
                      {t2High?.gstatus === "0" && <OverflowContant />}
                    </div>
                    <span>0</span>
                  </div>
                  {/* <span className="min-max">min:{ t2High?.min} || max: {t2High?.max}</span> */}
                </div>
                <span className="min-max">min:{ t2Low?.min} || max: {t2Low?.max}</span>
              </div>
              <div style={{ margin: "0px" }} className="row cmc">
                <div className="select_bet_box col-md-5 mt-2 ">             
                     <div className=" cmc ">
                  <div
                    onClick={() => {
                      t2Even?.gstatus !== "0" && handelPlacebet(t2Even);
                    }}
                    className="tc d-grid"
                    style={{ width: "45%" }}
                  >
                    <span>{t2Even?.rate}</span>
                    <div style={{ position: "relative", width: "100%" }}>
                      <button
                        style={{ width: "100%" }}
                        className="bet_box_even"
                      >
                        EVEN
                      </button>
                      {t2Even?.gstatus === "0" && <OverflowContant />}
                    </div>
                    <span>0</span>
             
                  </div>
                  <div
                    onClick={() => {
                      t2Odd?.gstatus !== "0" && handelPlacebet(t2Odd);
                    }}
                    className="tc d-grid"
                    style={{ width: "45%" }}
                  >
                    <span>{t2Odd?.rate}</span>
                    <div style={{ position: "relative", width: "100%" }}>
                      <button
                        style={{ width: "100%" }}
                        className="bet_box_even"
                      >
                        ODD
                      </button>
                      {t2Odd?.gstatus === "0" && <OverflowContant />}
                    </div>
                    <span>0</span>
                    {/* <span className="min-max">min:{ t2Odd?.min} || max: {t2Odd?.max}</span> */}
                  </div>
                  </div>
                  <span className="min-max">min:{ t2Even?.min} || max: {t2Even?.max}</span>
                </div>
                <div className="select_bet_box col-md-5 mt-2">    
                <div className=" cmc ">
                  <div
                    onClick={() => {
                      t2Red?.gstatus !== "0" && handelPlacebet(t2Red);
                    }}
                    className="tc d-grid"
                    style={{ width: "45%" }}
                  >
                    <span>{t2Red?.rate}</span>
                    <div style={{ position: "relative", width: "100%" }}>
                      <button
                        style={{ width: "100%" }}
                        className="bet_box_even"
                      >
                        <div className="luckeybet-sign-button">
                          <img src={heart} />
                          <img src={diamond} />
                        </div>
                      </button>
                      {t2Red?.gstatus === "0" && <OverflowContant />}
                    </div>
                    <span>0</span>
                 
                  </div>
                  <div
                    onClick={() => {
                      t2Black?.gstatus !== "0" && handelPlacebet(t2Black);
                    }}
                    className="tc d-grid"
                    style={{ width: "45%" }}
                  >
                    <span>{t2Black?.rate}</span>
                    <div style={{ position: "relative", width: "100%" }}>
                      <button
                        style={{ width: "100%" }}
                        className="bet_box_even"
                      >
                        <div className="luckeybet-sign-button">
                          <img src={shade} />
                          <img src={club} />
                        </div>
                      </button>
                      {t2Black?.gstatus === "0" && <OverflowContant />}
                    </div>
                    <span>0</span>
                    {/* <span className="min-max">min:{ t2Black?.min} || max: {t2Black?.max}</span> */}
                  </div>
                </div>
                <span className="min-max">min:{ t2Red?.min} || max: {t2Red?.max}</span>
                </div>
              </div>
              <div className="select_bet_box">

              <div className="mt-2  d-flex justify-content-center cardssection">
                <div className="card-grid">
                  {cardsimgnew.map((card, index) => (
                    <div
                      onClick={() => {
                        card.data?.gstatus !== "0" && handelPlacebet(card?.data);
                      }}
                      key={index}
                      className="card-container"
                    >
                      <div
                        style={{ backgroundColor: "red", position: "relative" }}
                        className=""
                      >
                        <img
                          className="card-image"
                          src={card.name}
                          alt={`Card ${index}`}
                        // onClick={() => handleCardClick(card.value)}
                        />
                        {card.data?.gstatus === "0" && <OverflowCard />}
                      </div>
                      <div className="card-text">{card.data?.rate}</div>
                
                    </div>
                  ))}                    
                </div>
             
          
              </div>
              <div> <span className="min-max">min:{ t2Card7?.min} || max: {t2Card7?.max}</span></div>
              </div>
              <div className="last_result  cmc">
                <span>Last Result</span>
                <span>View Result</span>
              </div>
              <div className="bet-result" style={{ padding: "10px 10px" }}>
                {Lucky7Result?.map((item, index) => (
                  <span
                    key={index}
                    style={{
                      color: item.result === "1" ? "red" : item.result === "2" ? "yellow" : "black"
                    }}
                  >
                    {item.result === "1" ? "L" : item.result === "2" ? "H" : item.result || "0"}
                  </span>
                ))}

              </div>
            </div>
          </div>

          <div className="col-md-4">

          {notCompletedBetList && notCompletedBetList.length != 0 && 

<div>
<div className="place-bet-h">Open Bet List</div>
              <CompletedBetList columns={columnsopenbet} listData={notCompletedBetList} />
            </div>
}


 {checkProfitLoss(betlist)?.totalAmount !== 0 &&
            <div>
              <div className="place-bet-h cmc">
                <div>Completed Bet List</div>
                <div
                  style={{
                    color: checkProfitLoss(betlist)?.isProfit
                      ? "yellow"
                      : "red",
                  }}
                >
                  P/L: {checkProfitLoss(betlist).profitLossAmount}
                </div>
              </div>
              <CompletedBetList columns={columns} listData={completedBetList} />
            </div>
}

</div>
        </div>
      </div>

      <div style={{ marginTop: "10px" }}>
        <BackButton onClick={handleClick} />
      </div>
    </>
  );
};
export default LuckySeven;

const OverflowContant = () => {
  return (
    <>
      <div className="overflow-dragon-tiger-btn1">
        <LockIcon sx={{ color: "white" }} />
      </div>
    </>
  );
};

const OverflowCard = () => {
  return (
    <>
      <div className="overflow-dragon-tiger-card">
        <LockIcon sx={{ color: "white" }} />
      </div>
    </>
  );
};
