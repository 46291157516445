
import { useState } from 'react';
import casinotable from '../../assets/images/casino-girl3.jpg'
import katrina from '../../assets/images/cardimage/katrina_resized.jpg'
import deepika from '../../assets/images/cardimage/deepika_resized.jpg'

const KatrinaDeepika = () => {
    const [placebet, setPlacebet] = useState(false)

    const betresult = [
        'K', 'D', "K", "K", "D", 'K', 'D', "K", "K", "D",
    ]


    return (<>
        <div className="row">
            <div className="teenpatti container-fluid col-md-8">
                <div className="teenpatti-header cmc">
                    <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
                        50-50 Katrina Deepika Lottery Rules
                    </span>
                    <span>
                        Round ID: 240209142817 | Min: 100 | Max: 150000
                    </span>
                </div>
                <div className="row">
                    <div className='col-6 tc'>
                        <div className="image-wrapper">
                            <div className="image-container">
                                <img src={katrina} alt="Katrina" className="img-responsive" />
                            </div>
                            <button className="mt-2 btn-lottery">Katrina Lottery</button>
                        </div>
                    </div>
                    <div className='col-6 tc'>
                        <div className="image-wrapper">
                            <div className="image-container">
                                <img src={deepika} alt="Deepika" className="img-responsive" />
                            </div>
                            <button className="mt-2 btn-lottery">Deepika Lottery</button>
                        </div>
                    </div>
                </div>
                <div className=" mt-3">
                    <div className=" cmc last_result">
                        <span>Last Result</span>
                        <span>View Result</span>
                    </div>
                    <div className="bet-result mt-3" style={{ padding: "10px 10px" }}>
                        {betresult.map((item, index) => (
                            <span key={index}>{item}</span>
                        ))}
                    </div>
                </div>
            </div>

            <div className="teenpatti-betsection col-md-4">
                <div className="container-fluid p-0">
                    <div className="row ">
                        <div className="col-md-12 place-bet">

                            {/* {`place-bet-container ${isTrue ? 'placebet' : ''}` */}

                            <div className={`place-bet-container placebet ${placebet ? "" : "d-none"}`}>
                                <div className="card">
                                    <div className="place-bet-h"><span>Place Bet</span></div>
                                    <div className="card-body bg-info">
                                        <table className="table table-bordered text-center">
                                            <thead>
                                                <tr>
                                                    <th>Bet for</th>
                                                    <th>Odds</th>
                                                    <th>Stake</th>
                                                    <th>Profit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <button onClick={() => { setPlacebet(false) }} className="btn btn-danger btn-sm">X</button> Player A
                                                    </td>
                                                    <td>
                                                        <input type="number" value="1.98" className="form-control" />
                                                    </td>
                                                    <td>
                                                        <input type="number" value="0" className="form-control" />
                                                    </td>
                                                    <td>0</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div style={{ height: '40px' }} className="btn-group btn-group-sm d-flex justify-content-between" role="group">
                                            <button className="btn btn-secondary">100</button>
                                            <button className="btn btn-secondary">500</button>
                                            <button className="btn btn-secondary">10,000</button>
                                            <button className="btn btn-secondary">25,000</button>
                                            <button className="btn btn-secondary">50,000</button>
                                        </div>
                                        <div style={{ height: '40px' }} className="btn-group btn-group-sm d-flex justify-content-between" role="group">

                                            <button className="btn btn-secondary">100,000</button>
                                            <button className="btn btn-secondary">200,000</button>
                                            <button className="btn btn-secondary">500,000</button>
                                            <button className="btn btn-secondary">1,000,000</button>
                                            <button className="btn btn-secondary">2,500,000</button>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-between">
                                        <button className="btn btn-danger">Reset</button>
                                        <button className="btn btn-success">Submit</button>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-2 place-bet-h">My Bet</div>
                            <table className="table table-bordered text-center">


                                {/* 
    <thead>My Bet</thead> */}
                                <tbody>
                                    <tr>
                                        <td className="box-6">Match Bet</td>
                                        <td className="box-2">
                                            Odds

                                        </td>
                                        <td className="box-2">
                                            Stake
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-12 betting-rule mt-2  " style={{ backgroundColor: "#e6e6e6" }}>



                            <div className="mt-2 place-bet-h tc">Rules</div>

                            <table className="table table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>Hand Type</th>
                                        <th>Payout</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Pair (Double)</td>
                                        <td>1 To 1</td>
                                    </tr>
                                    <tr>
                                        <td>Flush (Color)</td>
                                        <td>1 To 4</td>
                                    </tr>
                                    <tr>
                                        <td>Straight (Rown)</td>
                                        <td>1 To 6</td>
                                    </tr>
                                    <tr>
                                        <td>Trio (Teen)</td>
                                        <td>1 To 35</td>
                                    </tr>
                                    <tr>
                                        <td>Straight Flush (Pakki Rown)</td>
                                        <td>1 To 45</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </>
    );

}
export default KatrinaDeepika


