
export const checkProfitLoss = (dataList) => {

    let data = {
      totalAmount: 0,
      winningAmount: 0,
      lossAmount: 0,
      profitLossAmount: 0,
      isProfit: true
    }

    data.totalAmount = dataList
      .filter(bet => bet.completed)
      .reduce((total, bet) => total + bet.amount, 0);

    data.winningAmount = dataList
      .filter(bet => bet.completed && bet.win)
      .reduce((total, bet) => total + bet.profitAndLoss, 0);

    data.lossAmount = dataList
      .filter(bet => bet.completed && !bet.win)
      .reduce((total, bet) => total + bet.profitAndLoss, 0);

    data.isProfit = data.winningAmount >= (-data.lossAmount) ? true : false

    data.profitLossAmount = data.winningAmount + (data.lossAmount)



    return data
  }