import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";


import CommanTable from "../common/commontable";
import { GetAccountStatement, UseAccountStatement } from "../../api/login/useLogin";
import { OperationType } from "../models";
import { Calendar } from "../common/calender";
import { formatDate } from "../../service/utilities/dateFormater";
import { BackButton } from "../common/backbutton";
import { home } from "../../routesconfig/constant";
import { roundToDecimals } from "../../service/utilities/roundDecimal";
const AccountStatement = () => {
  const navigate = useNavigate();
  const [operationtype, setOperationType] = useState(0);
  const accountid = JSON.parse(sessionStorage.getItem("account") || "{}");
  const userId = sessionStorage.getItem("userid");

  const { submit: getStatementData } = UseAccountStatement()

  const [dateObj, setDateObj] = useState({ startDate: null, endDate: null });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [agenttabledata, setAgentTabledata] = useState();
  const [totalDataLength, setTotalDataLength] = useState(false);



  const paginationdata = {
    userId: "4231d2e5-b920-42fe-8d4a-eea58f64a0c4",
    operationType: operationtype,
    startDate: dateObj?.endDate ? dateObj?.startDate : null,
    endDate: dateObj?.startDate ? dateObj?.endDate : null,
    searchListRequest: {
      searchKey: "",
      sortKey: "",
      sortDirection: "",
      itemsPerPage: rowsPerPage,
      page: page + 1,
    },
  };

  const fatchStatementData = () => {



    getStatementData(paginationdata, (response) => {
      debugger
      const agenttabledata = response?.data?.records?.map(
        (res, index) => ({
          ...res,
          createdDate: formatDate(res.createdDate),
          cr: res?.type === 1 ? Math.abs(roundToDecimals(res.amount)) : null, // 'cr' contains amount if type is 1, otherwise null
          dr: res?.type === 2 ? Math.abs(roundToDecimals(res.amount)) : null, 
          prevbalance:
            res?.type === 2
              ? roundToDecimals(Math.abs(res?.amount) + Math.abs(res?.balance))
              : roundToDecimals(Math.abs(res?.balance) - Math.abs(res?.amount)), // Add 'cr' if type is 1
        })
      );
      setAgentTabledata(agenttabledata);
      setTotalDataLength(response?.data?.totalRecords);
    });
  }
  useEffect(() => {
    if (accountid) {
      fatchStatementData()
    }
    console.log(rowsPerPage, page)
  }, [operationtype, page, rowsPerPage, dateObj]);

  // useEffect(() => {
  //   if (dateObj?.startDate && dateObj?.endDate) {
  //     fatchStatementData()
  //   }
  // }, [dateObj])

  const columns = [
    { id: "createdDate", label: "Date", align: "center" },
    { id: "remarks", label: "Description", align: "center" },
    { id: "prevbalance", label: "Prev.Bal", align: "center" },
    { id: "cr", label: "CR", align: "center" },
    { id: "dr", label: "DR", align: "center" },
    { id: "balance", label: "Balance", align: "center" },
  ];


  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt((event?.target?.value || 10), 10));
    setPage(0); // Reset to first page whenever rows per page changes
  };

  const onDateChange = (dateObject) => {
    setDateObj(dateObject);
    handleRowsPerPageChange();
  };

  const handleClick = () => {
    navigate(home);
  };
  const handleAllTypeOperation = () => {
    setOperationType(0);
    handleRowsPerPageChange()
  };

  const handleAccountOperation = () => {
    setOperationType(OperationType.Account);
    handleRowsPerPageChange()
  };

  const handleProfitLossOperation = () => {
    setOperationType(OperationType.ProfitAndLoss);
    handleRowsPerPageChange()
  };
    const handleCommissionOperation = () => {
      setOperationType(OperationType.Commission);
    handleRowsPerPageChange()
  };


  const tabletool = () => {
    return <>
      <div className="tableTool-button statement Desktopview me-3">
        <Button
          onClick={() => {
            handleAllTypeOperation()
          }}
          sx={{ backgroundColor: "" }}
          variant="contained"
        >
          All
        </Button>
        <Button
          onClick={() => {
        
            handleProfitLossOperation()
          }}
          sx={{ backgroundColor: "#5856D6" }}
          variant="contained"
        >
          P&L
        </Button>
        <Button
          onClick={() => {
          
            handleCommissionOperation()
          }}
          sx={{ backgroundColor: "#5856D6" }}
          variant="contained"
        >
          Comm
        </Button>
        <Button
          onClick={() => {
            handleAccountOperation()
          }}
          sx={{ backgroundColor: "#5856D6" }}
          variant="contained"
        >
          Acount
        </Button>
      </div>
    </>
  }





  return (
    <>
      <BackButton onClick={handleClick} />
      <div className="form-box mt-2" style={{}}>
        <div className="row">
          <div className="col-md-6  mt-2 cmd" style={{}}>
            <Calendar onDateChange={onDateChange} />
          </div>

          <div className="col-md-6 cmd mt-2 pl-2 Mobview">
            <div className="tableTool-button statement">
              <Button
                onClick={() => {
                  handleAllTypeOperation()
                }}
                sx={{ backgroundColor: "" }}
                variant="contained"
              >
                All
              </Button>
              <Button
                onClick={() => {
                  handleProfitLossOperation()
                }}
                sx={{ backgroundColor: "#5856D6" }}
                variant="contained"
              >
                P&L
              </Button>
              <Button
                onClick={() => {               
            handleCommissionOperation()
                }}
                sx={{ backgroundColor: "#5856D6" }}
                variant="contained"
              >
                Comm
              </Button>
              <Button
                onClick={() => {
                  handleAccountOperation();
                }}
                sx={{ backgroundColor: "#5856D6" }}
                variant="contained"
              >
                Acount
              </Button>
            </div>
          </div>

        </div>
        <div className="mt-2">
          <CommanTable
            title={"Transaction"}
            columns={columns}
            rows={agenttabledata}
            totalDataLength={totalDataLength}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            tabletool={tabletool}
          />
        </div>
      </div>
    </>
  );
};

export default AccountStatement;


