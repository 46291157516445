import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import '../../assets/matchSummary.css';

export const MatchSummary = ({scorelink}) => {

   const scoreCardUrl = `${scorelink}`

 
   const height = scoreCardUrl.includes("777")  ? '190px' : 'auto';
  return (
   <>
   <div style={{padding:'0px', marginTop:'5px'}} className='scorecard'>
   <iframe
   style={{
    width:"100%",
    height: height
   }}
        src= {scoreCardUrl}   
    >
    </iframe>

   </div>
   </>
  );
};

