export const home = '/';
export const login = "/"
export const technicalerror = '/technicalerror' 
export const changeclientpassword = "/change-password"
export const inplay = "/inplay"
export const inplaysession = '/inplaysession'
export const profile = '/profile'
export const myledger = '/myledger'
export const casino = '/casino'
export const instruction = '/instruction'
export const akbarlottery = '/akbarlottery'
export const coinflip = '/coinflip'
export const dragontiger = '/dragontiger'
export const teenpattigame = '/teenpatti'
export const luckyseven = '/luckyseven'
export const amarakbar = '/amarakbar'
export const katrinadepika = '/katrinadepika'
export const avaitorgame = '/avaitor'
export const andarbahargame = '/andarbahar'
export const casinogameresult = '/casinogameresult'
export const errorresult = '/errorresult'
export const accountstatement = 'accountstatement'